import React, { useEffect } from 'react';

const ReactMarkdown = require('react-markdown')

const FormattedText = (props) => {
    const text = props.children

    var markdownText = (text ?? '').replace(/\\n/gi, "\n") 

    return (
        <ReactMarkdown source={markdownText}/>
    )
}

export default FormattedText