import React from 'react';
import ReactDOM from 'react-dom';
import "./landing.scss"
import { useAppState } from '../AppState';

const LandingPage = () => {

    const appState = useAppState()

    return (<div>
        <section className="hero">
            <div className="content row">
                <div className="copy-container">
                    <div className="inner">
                        <h1>FutureAmp</h1>
                        <p class="header">Online Learning</p>
                    </div>
                </div>
                <div className="image"></div>
            </div>
        </section>

        <section className="intro">
            <div className="content">
                <h3></h3>
                <p></p>
            </div>
        </section>
    </div>)
}

export default LandingPage;