export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/login';
export const SIGN_OUT = '/signout';
export const COURSE_LEGACY = '/course/:access_id';
export const COURSE_LANDING = '/courses/:course_id/:access_id';
export const COURSE = '/courses/:course_id/:access_id';
export const COURSE_ENROLL = '/courses/:course_id/:access_id/enroll';
export const COURSE_COMPLETE = '/courses/:course_id/:access_id/complete';
export const COURSE_STEP = '/courses/:course_id/:access_id/step/:step_id';



export const FORGOT_PASSWORD = '/forget-password';


export const ADMIN_MENTOR = '/admin-mentor/:id';

