import React from 'react';
import ReactDOM from 'react-dom';
const ReactMarkdown = require('react-markdown')

export const CourseComponentImage = (props) => {
    const { height, url } = props

    var style = {
        height: height + "px",
        width: "100%",
        backgroundSize: 'contain',
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundImage: 'url(' + url + ')'
    }


    return (<div className="component component-image" style={style}></div>)
}