import React, { useEffect } from 'react';

const Grid = (props) => {
    var gridProps = {
        columns: 4,
        columnGap: "25px",
        rowGap: "25px",
        ...props
    }

    var columnCSS = "100%"
    var columns = gridProps.columns
    var gridClass = "grid-3-column"
    if (columns == 4) {
        gridClass = "grid-4-column"
    } else if (columns == 3) {
        gridClass = "grid-3-column"
    } else if (columns == 2) {
        gridClass = "grid-2-column"
    }

    let gridStyle = {
        display: "grid",
        width: "100%",
        gridColumnGap: gridProps.columnGap,
        gridRowGap: gridProps.rowGap,
    }

    return (
        <div className={"grid " + gridClass} style={gridStyle}>{props.children}</div>
    )
}

export default Grid