import React from 'react';
import Select from 'react-select'
import Spinner from 'reactjs-simple-spinner'
import FormattedText from '../controls/FormattedText'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: "#F8F8F8",
        height: 55,
        border: "0px",
        paddingLeft: "10px"
    }),

    indicatorSeparator: (provider, state) => ({
        border: "0px"
    })
}

export const Form = (props) => {
    const onSubmit = (event) => {
        props.onSubmit()
        event.preventDefault()
    }

    var errorContent = null
    if (props.error) {
       errorContent = (<section>
            <div class="content">
                <FormError>{props.error}</FormError> 
            </div>
        </section>)
    }

    var content = props.loading ? null : props.children
    return <form onSubmit={onSubmit}>
        {props.error && errorContent }
        {content}
    </form>
}

export const FormError = (props) => {
    return (
        <div class="message message-error">{props.children}</div>
    )
}

export const FormRow = (props) => {
    return (
        <div class="form-row">{props.children}</div>
    )
}

export const FormAction = (props) => {
    return (
        <div class="form-actions">
            <div class="form-action-info"><FormattedText>{props.text}</FormattedText></div>
            <div class="form-action-right">
                {props.children}
            </div>
        </div>
    )
}

// export const FormActions = (props) => {
//     return (
//         <div class="form-actions">{props.children}</div>
//     )
// }

// export const FormActionRight = (props) => {
//     return (
//         <div class="form-action-right">{props.children}</div>
//     )
// }

// export const FormActionInfo = (props) => {
//     return (
//         <div class="form-action-info">{props.children}</div>
//     )
// }

export const FormSubmit = (props) => {
    var content = props.children
    var disabled = props.disabled
    if (props.loading) {
        content = <Spinner lineFgColor="#3863FC" size="small"/>
        disabled = true
    }
    var sizeClass = props.size != null ? sizeClass = "primary-" + props.size : "primary"
    var className = disabled ? `submit ${sizeClass}-disabled` : `submit ${sizeClass}`
    if (props.autoWidth) {
        className += ' auto-width'
    }
    const onClick = (event) => {
        if (props.onSelect) {
            props.onSelect()
            event.preventDefault()
        }
    }

    return (
        <button disabled={disabled} class={className} onClick={onClick}>{content}</button>
    )
}

export const FormInput = (props) => {
    return (
        <input type="textfield" className="textfield" {...props}/>
    )
}

export const FormTextArea = (props) => {
    return (
        <textarea className="textfield" {...props}/>
    )
}


export const FormLabel = (props) => {
    return (
        <label>{props.children}</label>
    )
}

export const FormSelect = (props) => {
    var value = props.value
    if (props.optionValue) {
        props.options.forEach( option => {
            if (option.value == props.optionValue) {
                value = option
            }
        })
    }
    return <Select styles={customStyles} {...props}  value={value}/>
}

export class FormHelpers {
        /**
     * Helpers
     */
    static optionsForDocs(docs) {
        return docs.map( (doc) => {
            return FormHelpers.optionForDoc(doc)
        })
    }

    static optionForDoc(record) {
        if (record == null) {
            return null
        }
        return {
            value: record.id,
            label: record.data().title
        }
    }
}