import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import { firestore } from 'firebase';
//const admin = require('firebase-admin');
// /const functions = require('firebase-functions');

class FirebaseMentor {
    constructor(firebase, mentor_id) {
        this.firebase = firebase
        this.mentor_id = mentor_id
    }

    updatePlayCount() {
        if (this.firebase.user == null) { return }

        // Record play count
        let doc = this.firebase.db.collection('user_mentors').doc(this.firebase.user.uid + '/mentor/' + this.mentor_id)
        doc.get().then( result => {
            var play_count = result.data() && result.data().play_count ? result.data().play_count : 0
            play_count++;
            doc.set({ 
                play_count: play_count,
                play_last_date: new Date() 
            }, { merge: true })
        })    
    }

    updateViewCount() {
        if (this.firebase.user == null) { return }

        // Record play count
        let doc = this.firebase.db.collection('user_mentors').doc(this.firebase.user.uid + '/mentor/' + this.mentor_id)
        doc.get().then( result => {
            var view_count = result.data() && result.data().view_count ? result.data().view_count : 0
            view_count++;
            doc.set({ 
                view_count: view_count,
                view_last_date: new Date() 
            }, { merge: true })
        })   
    }

    userMentorData(callback) {
        if (this.firebase.user == null) { return }

        this.firebase.db.collection('user_mentors/' + this.firebase.user.uid + '/mentor/').doc(this.mentor_id).get().then( result => {
            callback(result)
        })
    }

    updateComment(comment, callback) {
        if (this.firebase.user == null) { return }

        let doc = this.firebase.db.collection('user_mentors').doc(this.firebase.user.uid + '/mentor/' + this.mentor_id) 
        doc.set({ 
            comment: comment,
            comment_date: new Date()
        }, { merge: true }).then( () => {
            callback()
        })    
    }
    
    deleteComment(callback) {
        if (this.firebase.user == null) { return }

        let doc = this.firebase.db.collection('user_mentors').doc(this.firebase.user.uid + '/mentor/' + this.mentor_id) 
        doc.set({ 
            comment: null,
            comment_date: null
        }, { merge: true}).then (() => {
            callback()
        })
        //doc.delete().then( () =>  { 
        //    callback()
        //})
    }
}

class FirebaseCourse {
    constructor(firebase, course_id) {
        this.firebase = firebase
        this.course_id = course_id
    }
}

const config = {
  apiKey: "AIzaSyBlqtX2IlVtp1X-Q-VdqtJtKba2OFG54Kw",
  authDomain: "futureamp-learning.firebaseapp.com",
  databaseURL: "https://futureamp-learning.firebaseio.com",
  projectId: "futureamp-learning",
  storageBucket: "futureamp-learning.appspot.com",
  messagingSenderId: "324407067627",
  appId: "1:324407067627:web:52bc7a43d5488c592f0fae",
  measurementId: "G-XEZ2M2TKFC"
};

  class Firebase {
    // Auth user callback
    onAuthUserChanged = null
    onAuthUserChangedEnroll = null

    authUserListener = []

    // Auth user
    user = null
    db = null
    storage = null
    analytics = null

    constructor() {
        app.initializeApp(config);
        app.analytics();
        
        /**
         * Setup Auth
         */
        this.AUTH_SESSION = app.auth.Auth.Persistence.SESSION

        this.auth = app.auth();
        if (this.auth.currentUser) {
            this.user = this.auth.currentUser
        }
        this.authListener = this.auth.onAuthStateChanged( (authUser) => {
            this.user = authUser

            if (this.onAuthUserChanged) {
                this.onAuthUserChanged(this.user)
            }

            if (this.onAuthUserChangedEnroll) {
                this.onAuthUserChangedEnroll(this.user)
            }            
        })

        
        /**
         * Setup DB
         */
        this.db = app.firestore()
        this.storage = app.storage()
        this.analytics = app.analytics()
        //admin.initializeApp(functions.config().firebase);
    }

    course = (course_id) => {
        return new FirebaseCourse(this, course_id)
    }

    documentId() {
        return firestore.FieldPath.documentId()
    }

    createUser = (email, password, firstName, lastName,age,state, onSuccess, onError) => {
        this.auth.createUserWithEmailAndPassword(email, password).then(authUser => {
            let user = app.auth().currentUser

            this.db.collection('user_profiles').doc(user.uid).set({
                firstName: firstName,
                lastName: lastName,
                age: age,
                state: state,
                homescreen: 'default'
            }, { merge: true }).then( () => {

                user.updateProfile({ 
                    displayName: firstName + ' ' + lastName
                }).then( () => {
                    onSuccess(authUser)
                })
                
            })

            this.analytics.logEvent('user_signup', { name: firstName + ' ' + lastName })

            //alert(this.user)
            
        }).catch(error => {
            onError(error)
        })
    }

    login = (email, password, onSuccess, onError) => {
        this.auth.signInWithEmailAndPassword(email, password).then(authUser => {
            onSuccess(authUser)
        }).catch(error => {
            onError(error)
        })
    }

    signOut = () => {
        this.auth.signOut()
    }


    /**
     * Generic fetch
     */
    collection = (collectionName, callback) => {
        this.db.collection(collectionName).get().then( snapshot => {
            var data = snapshot.docs
            callback(data)
        }).catch(error => {
            console.error(error)
        })

    }

    /**
     * Helpers
     */
    docForId = (docs, id) => {
        
        var retDoc = null
        docs.forEach( doc => {
            if (doc.id == id) {
                retDoc = doc
            }
        })
        return retDoc
    }
    
    docRefForId = (docs, id) => {
        var doc = this.docForId(docs, id)
        if (doc) {
            return doc.ref
        } else {
            return null
        }
    }

    // Helper to load
    titleForReferenceData = (id, items) => {
        if (items == null || items.length == 0) {
            return null
        }
        var title = null
        items.forEach( item => {
            if (item.id == id) {
                title = item.data().title
            }
        })
        return title
    }

    /**
     * Update Profile
     */
    updateProfile = (data, callback, errorCallback) => {
        this.db.collection('user_profiles').doc(this.user.uid).set(data, { merge: true }).then( () => {
            callback()
        }).catch(error => {
            console.log(error)
            if (errorCallback) {
                errorCallback(error)
            }
        })

    }

    loadProfile = (callback) => {
        if (this.user == null) {
            return//callback(null)
        }
        console.log('getting profile')
        this.db.collection('user_profiles').doc(this.user.uid).get().then( (doc) => {
            console.log('profile:')
            callback(doc)
        })
    }


  }
  export default Firebase;
