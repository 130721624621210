import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'

export const CourseComponentVideo = (props) => {
    let ref = React.useRef(null)
    let playerRef = React.useRef(null)

    useEffect( () => {
        // Load the IFrame Player API code asynchronously.
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/player_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, [])

    var thumb = props.thumbnail ? props.thumbnail : 'https://img.youtube.com/vi/' + props.ytvideoid + '/maxresdefault.jpg'
    let style = {
        backgroundImage: 'url(' + thumb + ')' 
    }

    const playVideo = () => {
        playerRef.current = new window.YT.Player('ytplayer', {
            height: '100%',
            width: '100%',
            videoId: props.ytvideoid,
            playerVars: {
                autoplay: 1,
                modestbranding: 1,
                rel: 0
            }
        });

        // Analytics
        //firebase.analytics.logEvent('viewed_mentor_video', { name: props.mentorId })

        // Update Play Count
        //firebase.mentor(props.mentorId).updatePlayCount()
    }


    return (
    <div className="video" ref={ref} style={style} onClick={playVideo}>        
        <div id="ytplayer" className="yt-player">
            <div class="play-button"></div>
        </div>
    </div>)
}

