import React from 'react';
import { RadioItem  } from "../controls/Radio"
export const CheckboxAnswer = (props) => {
    return (<div className="quiz-item" onClick={props.onClick}>
        <RadioItem selected={props.selected}/>
        <label><span>{props.answer}</span></label>
    </div>)
}

export const CourseComponentCheckbox = (props) => {
    const { id,text, selectedIndex } = props

    const didSelect = (idx) => {
        if (props.onChange) {
            props.onChange(id, idx)
        }
    }
    
    let checkbox = <CheckboxAnswer key={"answer"} answer={text} selected={0 == selectedIndex} onClick={() => {
           didSelect(0)}
        }/>

    return (<div className="component component-checkbox">
                <div className="checkbox-item answers">
                    {checkbox}
                </div>
            </div>)
}
