import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import Grid from "../controls/Grid"
import SkillCard from "../controls/SkillCard"

const skills = [{
    title: "Creativity",
    id: "creativity",
    description: "Creativity is when you use your imagination and curiosity to explore new ideas and possibilities.",
    longDescription: "Creativity is important in STEM careers because STEM requires you to use your imagination and curiosity to explore new ideas and possibilities."
},
{
    title: "Problem Solving",
    id: "problem-solving",
    description: "Problem solving is the process of finding solutions to difficult or complex challenges.",
    longDescription: "Problem solving is important in STEM careers because you will need to find solutions to difficult or complex challenges."
},

{
    title: "Communication",
    id: "communication",
    description: "Communication is how you connect with others by speaking, writing or online. ",
    longDescription: "Communication is important in STEM careers because you will need to connect, collaborate and communicate with others by speaking, writing or online"
},

{
    title: "Financial Literacy",
    id: "financial-literacy",
    description: "Financial literacy is understanding money, finances and investing. ",
    longDescription: "Financial literacy is important in STEM careers because understanding money, finances and investing is fundamental to business operations and success."
},


{
    title: "Adaptability",
    id: "adaptability",
    description: "Adaptability is the ability to change your actions, behaviour or mindset to suit a new situation or purpose.",
    longDescription: "Adaptability is important in STEM careers because often you will be dealing with experiments, problems and uncertainty, so will need the ability to change your actions, behaviour or mindset to suit a new situation or purpose."
},

{
    title: "Leadership",
    id: "leadership",
    description: "Leadership is motivating and empowering a group of people to achieve a common goal.",
    longDescription: "Leadership is important in STEM careers because you will need to work in teams to motivate and empower a group of people to achieve a common goal."
},


{
    title: "Critical Thinking",
    id: "critical-thinking",
    description: "Critical thinking is the ability to analyse and synthesise complex information to form your own opinion.",
    longDescription: "Critical thinking is important in STEM careers because you will need the ability to analyse and synthesise complex information to form your own opinions or points of view."
},

{
    title: "Information Literacy",
    id: "information-literacy",
    description: "Information literacy is understanding how to find, analyse and use information for a particular purpose.",
    longDescription: "Information literacy is important in STEM careers because you will need to have skills in finding, analysing and using information for a particular purpose. "
},



{
    title: "Self Management",
    id: "self-management",
    description: "Self-management is managing your behaviour, well-being and learning, so you get the best out of yourself.",
    longDescription: "Self-management is important in STEM careers because you will need to manage your behaviour, well-being and learning, so you can get the best out of yourself."
},



{
    title: "Social Skills",
    id: "social-skills",
    description: "Social skills are how you connect, communicate and interact with other people, both verbally and through body language.  ",
    longDescription: "Social skills are important in STEM careers because you will need to connect, communicate and interact with other people, both verbally and through body language and gestures."    
},




{
    title: "Team Work",
    id: "team-work",
    description: "Teamwork is being able to work collaboratively and effectively with others to reach a shared goal.",
    longDescription: "Teamwork is important in STEM careers because you will need to work collaboratively and effectively with others to reach a shared goal."
},

{
    title: "Digital Literacy",
    id: "digital-literacy",
    description: "Digital literacy is the ability to use technology confidently and creatively in your life, work and study.",
    longDescription: "Digital literacy is important in STEM careers because you will need the ability to use technology confidently and creatively in your life, work and study. "
},



]

export const CourseComponentSkills = (props) => {
    const { selectedIndexes } = props

    var didSelect = (id) => {
        if (props.onSelect) {
            props.onSelect(props.id, id)
        }
    }

    var skillCards = skills.map( (skill, index) => {
        var id = skill.id
        if (id == null) {
            id = skill.title.toLowerCase().replace(' ', '-')
        }
        var description = skill.description
        var selected = false
        selectedIndexes.forEach(selectedItem => {
            if (id == selectedItem) {
                selected = true
            }
        })
        return <SkillCard onClick={ () => {
            didSelect(id)
        }} centered condensed id={id} selected={selected} iconName={id} description={description}>{skill.title}</SkillCard>
    })

    return (
        <div class="component component-skills">
        {props.title ? <h3>{props.title}</h3> : null}
        <Grid columns={2}>            
            {skillCards}
        </Grid>
        </div>
    )    


}


export const CourseComponentSkillResults = (props) => {
    const { selectedIndexes } = props

    var selectedSkills = []
    var unselectedSkills = []
    
    skills.forEach( skill => {
        var selected = false
        selectedIndexes.forEach(selectedItem => {
            if (skill.id == selectedItem) {
                selected = true
            }
        })

        var card = <SkillCard large id={skill.id} iconName={skill.id} description={skill.longDescription}>{skill.title}</SkillCard>
        if (selected) {
            selectedSkills.push(card)
        } else {
            unselectedSkills.push(card)
        }
    })

    var selectedTitle = "Great job! You're right - these skills are important in a STEM career. Here's the skills you selected: "
    var unselectedTitle = "As you might have noticed, there are many skills required in a STEM career. So, you could have also selected any of the below listed skills because they are also important in a STEM career! "
    return (
        <div class="component component-skills">
        
        <div class="skill-results-selected">
            {selectedTitle ? <p className="intro" style={{paddingBottom: "30px"}}>{selectedTitle}</p> : null}
            {selectedSkills}
        </div>
        
        <div class="skill-results-selected" style={{paddingTop: "100px"}}>
            {unselectedTitle ? <p className="intro intro-border" style={{paddingBottom: "35px"}}>{unselectedTitle}</p> : null}
            {unselectedSkills}
        </div>
        </div>
    )   

}