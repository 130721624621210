import React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { Course, useCourse } from '../Course';
import { Form, FormSelect, FormRow, FormInput, FormLabel, FormActionRow, FormSubmit, FormAction, FormError, FormHelpers } from '../Form'
import { CourseComponentImage } from "../CourseComponentImage"

/**
 * Router
 */
import { useAppRouter } from "../AppRouter"

export const CourseLandingPage = () => {

    const { course_id = "stem", access_id } = useParams()
    const courseState = useCourse(access_id)
    const router = useAppRouter()
    const [content, setContent] = React.useState(null)
    const ReactMarkdown = require('react-markdown')

    const actionContinue = () => {
        router.courseEnroll(course_id, access_id)
    }

    React.useEffect( () => {
        courseState.loadWelcome((data) => {
            setContent(data)
        })
    }, [])

    if (content == null) {
        return null
    }

    var welcomeText = content.text.replace(/\\n/gi, "\n") 

    return (<div className="landing">
            <header>
                    <div className="content">  

                        <div className="copy-container">
                        <h1>{content.title}</h1>
                        <h1 className="accent">{content.subtitle}</h1>
                    </div>

                    <CourseComponentImage url={content.image.url} height={content.image.height}/>
                        <div className="copy-container">
                            <ReactMarkdown source={welcomeText} /> 
                        </div>

                        <div className="action">
                            <FormSubmit autoWidth onSelect={actionContinue}>{content.button ?? "START COURSE HERE"}</FormSubmit>
                        </div>                        
                    </div>
            </header>
            <section className="credits">
                <div className="content">
                    <div className="copy-container">
                        <p>This course is designed by <a href="https://futureamp.co">Future Amp</a> and <a href="https://girledworld.com">girledworld</a></p>
                        <div className="logos">
                        <a href="https://girledworld.com"><img src="/logo-girledworld.png" width="10%"></img></a>
                        <a href="https://futureamp.co"><img src="/logo-futureamp.png" width="20%"></img></a>
                        </div>
                    </div>
                </div>
            </section>
    </div>)
}

export default CourseLandingPage;