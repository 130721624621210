import React from 'react';
import ReactDOM from 'react-dom';
import { Form, FormSelect, FormRow, FormInput, FormLabel, FormActionRow, FormSubmit, FormAction, FormError, FormHelpers } from '../Form'

export const CourseComponentContinue = (props) => {
    const { ref, hasContinued, buttonTitle, title, onSelect, description } = props

    let action = <div class="form-actions">
    				<div class="form-action-center">
    					<FormSubmit loading={false} disabled={false} onSelect={onSelect} >{buttonTitle}</FormSubmit>
    				</div>
				</div>
    
    let continueContent = ( <div>{title ? <h3>{title}</h3> : null} {description ? <p className="intro intro-border">{description}</p> : null} </div> )
    return (<section ref={ref} className="section-continue ">
                <div class="content">
                    {hasContinued ? continueContent : action}
                </div>
            </section>)
}