import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useAppState } from '../AppState';
import { Course, useCourse } from '../Course';
import { Form, FormSelect, FormRow, FormInput, FormLabel, FormActionRow, FormSubmit, FormAction, FormError, FormHelpers } from '../Form'


/**
 * Router
 */
import { useAppRouter } from "../AppRouter"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { useFirebase } from '../Firebase';
import { AppState } from '../AppState'
import { CourseState } from '../Course'

/**
 * Controls
 */
import PageHeader from "../controls/PageHeader"

const DefaultState = {
    firstName: '',
    lastName: '',
    age: '',
    yearLevel: '',
};

export const CourseEnrollPage = () => {

    const { course_id = "stem", access_id } = useParams()
    const appState = useAppState()
    const courseState = useCourse(access_id)

    const firebase = useFirebase()
    const router = useAppRouter()
    
    // Data
    const [ formState, setFormState ] = React.useState(DefaultState)
    const [ yearLevels, setYearLevels] = React.useState([])

    // Save / Loading / Error State
    const [ error, setError ] = React.useState(null)
    const [ saving , setSaving] = React.useState(false)

    // Loading
    const [ loadingReference, setLoadingReference ] = React.useState(true)
    const isLoading = loadingReference// || loadingProfile

    // Selection
    const [ selectedYearLevel, setSelectedYearLevel ] = React.useState(null)
    
    // Check fields
    var enrollFieldsCompleted = true
    if (courseState && courseState.course && courseState.course.enrollment_fields) {
        courseState.course.enrollment_fields.forEach( field => {
            if (formState[field.id] == null) {
                enrollFieldsCompleted = false
            }
        })
    } 

    // Vdliation
    const canContinue = (formState.firstName && formState.age && formState.yearLevel && enrollFieldsCompleted)


    // Helper for loading    
    const loadDropdown = (collectionName, setter) => {
        firebase.collection(collectionName, (data) => {
            var newData = data.filter(a => {
                return a.data().hide != 1
            }).sort( (a, b) => {
                return a.data().order - b.data().order
            })
            setter(newData)
        })
    }    

    /** When form field changes */
    const onFieldChange = (event) => {
        let newState = Object.assign({}, formState)
        newState[event.target.name] = event.target.value
        console.log(event.target.name)
        setFormState(newState)
    }    

    const onSelectChange = (option, event) => {
        let newState = Object.assign({}, formState)
        newState[event.name] = option.value//event.target.value
        setFormState(newState)
    }    
        
    // Load reference data
    useEffect( () => {
        loadDropdown('year_levels', setYearLevels)
        setLoadingReference(false)
    }, [])      

    const onSubmit = () => {
        console.log(formState)
        if (!canContinue) { return }

        setSaving(true)
        courseState.enroll(formState, () => {
            

            courseState.loadStep(1, (data) => {
                setSaving(false)

                //setCourseStep(data)
                //setPreLoading(false)
                router.courseStep(course_id, access_id, 1)
                //router.courseStep(access_id, courseState.current_step)
            })
            
        }, (error) => {
            setSaving(false)
            setError(error) 
        })
    }
    
    var enrollmentFields = courseState.course.enrollment_fields.map( field => {
        var inputComponent = null
        if (field.options) {
            var options = field.options.map( field => {
                return { value: field, label: field }
            })
            inputComponent = <FormSelect name={field.id}  optionValue={formState[field.id]} options={options} onChange={onSelectChange}/>
        } else {
            inputComponent =  <input type="textfield" className="textfield" placeholder="" name={field.id}  onChange={onFieldChange} value={formState[field.id]}></input>
        }
        return (<FormRow>
                            <div class="form-column-full">
                                <FormLabel>{field.title}</FormLabel>
                                {inputComponent}
                            </div>
                        </FormRow> )
    })

    return (<div>
       <PageHeader superTitle={courseState.school.name} title="Getting Started" description={courseState.course.enrollment_description} logo={courseState.course.icon}/>
       <section>
            <Form error={error} onSubmit={onSubmit}>            
                <section>
                    <div class="content">
                        
                        <FormRow>
                                <div class="form-column-half form-column-grouped">
                                    <FormLabel>Name</FormLabel>
                                    <input type="textfield" placeholder="First Name" className="textfield" name="firstName" onChange={onFieldChange} value={formState.firstName}></input>
                                </div>
                                <div class="form-column-half">
                                    <input type="textfield" placeholder="Last Name"  className="textfield" name="lastName" onChange={onFieldChange} value={formState.lastName}></input>
                                </div>
                        </FormRow>

                        <FormRow>
                            <div class="form-column-half">
                                <FormLabel>Age</FormLabel>
                                <input type="textfield" className="textfield" placeholder="18" name="age"  onChange={onFieldChange} value={formState.age}></input>
                            </div>
                            <div class="form-column-half">
                                <FormLabel>Year </FormLabel>
                                <FormSelect name={"yearLevel"} optionValue={selectedYearLevel} options={FormHelpers.optionsForDocs(yearLevels)} onChange={onSelectChange}/>
                            </div>
                        </FormRow>
                        {enrollmentFields}
                                               
                    </div>
                </section>
                <section className="form-fixed-footer">
                    <div class="content">
                        <FormAction>
                            <FormSubmit loading={saving} disabled={!canContinue}>Continue</FormSubmit>
                        </FormAction>
                    </div>
                </section>            
            </Form>                     
       </section>
    </div>)
}

export default CourseEnrollPage;