import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import Grid from "../controls/Grid"
import PickBoxCard from "../controls/PickBoxCard"

export const EmailForm = (props) => {
   const { fields } = props
   var margin = 0
   return (
<svg width="595px" id="email-form" height="842px" viewBox="0 0 595 842" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="email-pdf" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect fill="#FFFFFF" x="0" y="0" width="595" height="842"></rect>
        <g id="Group" transform="translate(32.000000, 37.000000)">
            <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="531" height="575" rx="9"></rect>
            <text id="Dear-_______,-My-nam" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="24" letter-spacing="0.247" fill="#000000">
                <tspan x={margin} y="30">Dear {fields[0]}, </tspan>
                <tspan x={margin} y="54"></tspan>
                <tspan x={margin} y="78">My name is  {fields[1]}, and I am a high school student from  {fields[2]}. </tspan>
                <tspan x={margin} y="102"></tspan>
                <tspan x={margin} y="126">I’m reaching out to contact you as I am currently making decisions about my future career </tspan>
                <tspan x={margin} y="150">pathways and I really admire your work in  {fields[3]}. </tspan>
                <tspan x={margin} y="174"></tspan>
                <tspan x={margin} y="198">I was wondering if you could spare some time to speak with me, or reply to some of my </tspan>
                <tspan x={margin} y="222">questions via email? </tspan>
                <tspan x={margin} y="246"></tspan>
                <tspan x={margin} y="270">In particular, I am interested in learning more about {fields[4]} (i.e how you got </tspan>
                <tspan x={margin} y="294">started in your career in {fields[5]}?) and what are the main skills you use</tspan>
                <tspan x={margin} y="318">in your workplace each day.</tspan>
                <tspan x={margin} y="342"></tspan>
                <tspan x={margin} y="366">I look forward to hearing from you. </tspan>
                <tspan x={margin} y="390"></tspan>
                <tspan x={margin} y="414">Thank you for taking the time to read this.</tspan>
                <tspan x={margin} y="438"></tspan>
                <tspan x={margin} y="462">Kind regards,</tspan>
                <tspan x={margin} y="486"></tspan>
                <tspan x={margin} y="510">{fields[6]}</tspan>
            </text>
        </g>
    </g>
</svg>)
}


export const CourseComponentEmailForm = (props) => {
    const { text } = props
    const [ fields, setFields] = React.useState([])

    const onFieldChange = (event, index) => {
        var newFields = [ ...fields ]
        newFields[index] = event.target.value
        
        setFields(newFields)
    }

    var fieldIndex = 0
    var rows = text.split('\\n\\n').map( (row) => {
        var segmentPieces = row.split('#input#')
        var segments = segmentPieces.map( (segment, index) => {
            var idx = fieldIndex
            var showInput = (index != segmentPieces.length - 1) 
            var input = showInput ? <input type="textfield" value={fields[idx]} onChange={ (event) => { onFieldChange(event, idx)}}></input> : null
            if (showInput) {
                fieldIndex = fieldIndex + 1            
            }
            return <span>{segment}{input}</span>
        })
        return <p>{segments}</p>
    })


    const downloadPDF = () => {

        //courseState.sendEvent('downloaded_email_pdf', new Date())

        require.config({
            baseUrl: './node_modules' // or './bower_components'
        });
        require([
            'svg2pdf.js/dist/svg2pdf.min',
            'jspdf-yworks/dist/jspdf.min'
        ], function (svg2pdf, jsPDF) {
            const svgElement = document.getElementById('email-form');
            const width = 595, height = 842;

            // create a new jsPDF instance
            const pdf = new jsPDF('p', 'pt', [width, height]);

            pdf.addFont('/Roboto-Regular.ttf', 'Roboto', 'normal');
            pdf.addFont('/Roboto-Bold.ttf', 'Roboto', 'bold');

            // render the svg element
            svg2pdf(svgElement, pdf, {
                xOffset: 0,
                yOffset: 0,
                scale: 1
            });

            // get the data URI
            const uri = pdf.output('datauristring');

            //var html = '<html><head></head><body>ohai</body></html>';
            //var windowuri = "data:text/pdf," + encodeURIComponent(uri);
            //var newWindow = window.open(windowuri);
            //newWindow.target = "new_pdf"
            // or simply save the created pdf
            pdf.save('futureamp-email.pdf');
        });

        return false
    }

    var svgStyle = {
        display: "none"
    }

    return (
        <div className="component component-email">
            <div className="email-form">
                <div className="email-content">
                    {rows}
                </div>

                <div className="email-footer">
                    <a className="download-button" onClick={downloadPDF}>Download your Future Amp Email Template</a>
                </div>

            </div>
            

            <div style={svgStyle}>
            <EmailForm fields={fields}/>
            </div>
        </div>
    )    


}

