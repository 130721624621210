import React from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react'
import "./course.scss"
import { useParams } from "react-router-dom";
import { useAppState } from '../AppState';
import { Course, useCourse } from '../Course';
import { Form, FormSelect, FormRow, FormInput, FormLabel, FormActionRow, FormSubmit, FormAction, FormError, FormHelpers } from '../Form'
import { ScrollTo } from "react-scroll-to";

/**
 * Router
 */
import { useAppRouter } from "../AppRouter"
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { CourseState } from '../Course'
import { CourseEnrollPage } from '../CourseEnrollPage'
import { CourseLandingPage } from '../CourseLandingPage'
import { CourseCompletePage } from '../CourseCompletePage'
import { CourseComponentVideo } from '../CourseComponentVideo'
import { CourseComponentImage } from '../CourseComponentImage'
import { CourseComponentQuiz, CourseComponentQuizResults } from '../CourseComponentQuiz'
import { CourseComponentCheckbox } from '../CourseComponentCheckbox'
import { CourseComponentRate , CourseComponentRateLarge } from '../CourseComponentRate'
import { CourseComponentPickABox, CourseComponentPickABoxResults  } from '../CourseComponentPickABox'
import { CourseComponentTextInput } from '../CourseComponentTextInput'
import { CourseComponentText } from '../CourseComponentText'
import { CourseComponentSkills, CourseComponentSkillResults } from '../CourseComponentSkills'
import { CourseComponentEmailForm } from '../CourseComponentEmailForm'
import { CourseComponentContinue } from '../CourseComponentContinue'

/**
 * Controls
 */
import ActionButton from '../controls/ActionButton';
import PageHeader from '../controls/PageHeader';

const ReactMarkdown = require('react-markdown')

export const RedirectLegacyCoursePath = () => {
    const {  access_id } = useParams()
    return <Redirect to={`/courses/stem/${access_id}`}/>
}

export const CoursePage = () => {

    const appState = useAppState()
    const { course_id, access_id } = useParams()
    
    return (<div>
        <CourseState courseId={course_id} accessId={access_id}>
            <CourseAccess>
                <Switch>
                    <Route path="/courses">
                        <Switch>
                            <Route path={ROUTES.COURSE_STEP} component={CourseStepPage} />       
                            <Route path={ROUTES.COURSE_ENROLL} component={CourseEnrollPage} />                   
                            <Route path={ROUTES.COURSE_COMPLETE} component={CourseCompletePage} />                   
                            <Route path={ROUTES.COURSE_LANDING} component={CourseLandingPage} />  
                        </Switch>
                    </Route>
                    <Route path={ROUTES.COURSE_LEGACY} >     
                        <RedirectLegacyCoursePath/>
                    </Route>
                </Switch>
            </CourseAccess>
       </CourseState>
    </div>)
}

export const CourseAccess = (props) => {
    const { access_id, step_id } = useParams()
    const courseState = useCourse(access_id)

    if (courseState.status == -1) {
        return <CourseNotFound/>
    } else {
        return props.children
    }
}

export const CourseNotFound = () => {
    return (<div className="landing">
            <header>
                <div className="content">
                    <div className="copy-container">
                        <h1>Course Not Found</h1>
                        <p>The course is unavailable.</p>
                        <div className="cover-image"></div>
                    </div>
                </div>
            </header>
    </div>)
}

export const CourseStepPage = () => {
    const { course_id, access_id, step_id } = useParams()
    const courseState = useCourse(access_id)
    const router = useAppRouter()
    const [courseStep, setCourseStep] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [preloading, setPreLoading] = React.useState(false)

    //const loading = (courseStep == null)

    /**
     * Validate step and session before showing a lesson
     */
    useEffect( () => {
        courseState.current_step = parseInt(step_id)

        // Max completed step
        var maxStep = courseState.userMaxStep()
        
        if (courseState.session_id == null) {
            router.courseEnroll(course_id, access_id)
        } else {
            if (courseState.current_step <= 0 || courseState.current_step == null) {
                router.courseStep(course_id, access_id, 1)
            } else if (courseState.current_step > maxStep + 1) {
                router.courseStep(course_id, access_id, maxStep + 1)
            }
        }
    }, [step_id, courseState.session_id])

    /**
     * Load Step
     */
    useEffect( () => {
        setLoading(true)
        //setCourseStep(null)
        courseState.loadStep(step_id, (data) => {
            setCourseStep(data)
            setLoading(false)
        })
    }, [step_id])

    /**
     * Step Continue
     */
    var stepContentContinue = (hasResults) => {        
        //setLoading(true)
        //setCourseStep(null)
        courseState.current_step = courseState.current_step + 1
        if (courseState.current_step > courseState.course.steps.length) {
            router.courseComplete(course_id, access_id)
        } else {
            setPreLoading(true)
            courseState.loadStep(courseState.current_step, (data) => {
                //setCourseStep(data)
                setPreLoading(false)
                router.courseStep(course_id, access_id, courseState.current_step)
            })
            //
        }
    }

    /**
     * Return if null
     */
    if (courseStep == null) { return null }

    var pageTitle = courseStep.title + (courseStep.isResults ? " - Results" : "")
    var pageSubtitle = 'STEP ' + (parseInt(step_id)) + ' OF ' + courseState.course.steps.length
    var description = courseStep.isResults ? "" : courseStep.description

    // description={courseStep.description}

    var progress = parseInt(step_id) / parseInt(courseState.course.steps.length)
    if (loading) {
        return (<div className="step">
                            <PageHeader progress={progress} superTitle={pageSubtitle} title={pageTitle} logo={courseState.course.icon}/>
                            
        </div>)
    }

    var userData = courseState.userDataForStepID(step_id)

    var pageContent = courseStep.isResults ? <CourseStepResults key={step_id} step={step_id} preloading={preloading} courseState={courseState} items={courseStep.items} onContinue={stepContentContinue} stepData={courseStep.userData}/> : 
                                             <CourseStepContent footer={courseStep.footer} description={description} key={step_id} step={step_id} preloading={preloading} stepData={userData} courseState={courseState} items={courseStep.items} onContinue={stepContentContinue}/>

                                                                                  
    return (<div className="step">
                <PageHeader progress={progress} superTitle={pageSubtitle} title={pageTitle}  logo={courseState.course}/>
                {pageContent}
            </div>) 
}

export const CourseStepResults = (props) => {
    var { step, courseState, preloading, onContinue, stepData } = props
    const [ saving , setSaving] = React.useState(false)

    const onSubmit = () => {
        // Mark complete
        setSaving(true)
        courseState.saveStep(step, {}, () => {
            setSaving(false)
            if (onContinue) {
                onContinue()
            }
        })    
        
    }    

    var content = props.items.map( item => {
        var component = null
        var type = item.type
    
        if (type == "quiz") {
            component = <CourseComponentQuizResults {...item.data} correctIndex={item.data.correct} id={item.id} answerIndex={stepData[item.id]} />
        } else if (type == "skills") {
            component = <CourseComponentSkillResults {...item.data} selectedIndexes={stepData[item.id]} />
        } else if (type == "pickabox") {
            component = <CourseComponentPickABoxResults  {...item.data}     id={item.id} columns={item.data.columns} items={item.data.items} selectedIndexes={stepData[item.id]} />
        }

        return <section className="component">
                    <div className="content">
                        {component}
                    </div>
                </section> 
    })
    
    var buttonTitle = "Continue"

    return (
        <div>
            <Form onSubmit={onSubmit}>
            {content}        
            <section className="form-fixed-footer">
                <div class="content">
                    <FormAction>
                        <FormSubmit loading={saving | preloading} disabled={false}>{buttonTitle}</FormSubmit>
                    </FormAction>
                </div>
            </section>  
            </Form>
        </div>
    )    
}

export const CourseStepContent = (props) => {
    var { step, preloading, courseState, onContinue, footer } = props

    var [ stepData , setStepData ] = useState(props.stepData)

    var dataInputComponents =  [
        "quiz", "rate", "input", "skills"
    ]

    // Save / Loading / Error State
    const [ error, setError ] = React.useState(null)
    const [ saving , setSaving] = React.useState(false)
    const [ hasContinued , setHasContinued] = React.useState(false)
    const continueRef  = React.useRef(null)

    const onSubmit = () => {
        if (!canContinue) { return }

        var dataFields = props.items.filter( field => {
            return (dataInputComponents.includes(field.type))
        })

        setSaving(true)
        courseState.saveStep(step, stepData, () => {
            setSaving(false)
            if (onContinue) {
                onContinue()
            }
        })    
        
    }    

    var componentValueChanged = (id, answer) => {
        var newData = {}
        newData[id] = answer
        console.log(answer)
        setStepData({ ...stepData, ...newData })
    }

    
    var componentMultiValueChanged = (id, answer) => {        
        var newData = stepData[id]
        if (newData == null) {
            newData = [answer]
        } else {
            var hasAnswer = false
            stepData[id].forEach( item => {
                if (item == answer) {
                    hasAnswer = true
                }
            })

            if (hasAnswer) {
                newData = stepData[id].filter( item => {
                    return item != answer
                })
            } else {
                if (newData.length < 3) {
                    newData.push(answer)
                }
            }
        }
        
        stepData[id] = newData
        setStepData({ ...stepData})
    }

    var continueComponentDidChange = () => {
        setHasContinued(true)
    }
    

    var afterContinue = false
    var componentWaitingForContinue = false

    React.useEffect( () => {
        if (hasContinued) {
            doScrolling(continueRef.current, 1000)
        }
    }, [hasContinued])

    var dataFields = 0
    var dataFieldsFilled = 0

    var content = props.items.map( (item, index) => {
        var component = null
        var type = item.type
        var isDataField = false
        var dataFieldAnswersNeeded = 1
        var extraProps = {}
        if (type == "quiz") {
            component = <CourseComponentQuiz {...item.data} id={item.id} selectedIndex={stepData[item.id]} onChange={componentValueChanged}/>
            isDataField = true
        } else if (type == 'image') {
            component = <CourseComponentImage {...item.data} id={item.id}/>
        } else if (type == "checkbox") {
            component = <CourseComponentCheckbox {...item.data} id={item.id} selectedIndex={stepData[item.id]} onChange={componentValueChanged}/>
            isDataField = true
    
        } else if (type == "video") {
            component = <CourseComponentVideo {...item.data} id={item.id}/>
        } else if (type == "text") {
            if (item.data.text) {
                component = <CourseComponentText id={item.id} text={item.data.text} style={item.data.style}/>
            } else {
                component = <CourseComponentText id={item.id} text={item.data}/>
            }
        } else if (type == "rate") {
            if (item.data.style == "large") {
                component = <CourseComponentRateLarge {...item.data} id={item.id} selectedIndex={stepData[item.id]} onChange={componentValueChanged}/>    
            } else {
                component = <CourseComponentRate {...item.data} id={item.id} selectedIndex={stepData[item.id]} onChange={componentValueChanged}/>
            }
            isDataField = true
        } else if (type == "email") {
            component = <CourseComponentEmailForm  {...item.data} id={item.id}/>        
        } else if (type == "input") {
            component = <CourseComponentTextInput key={step + "_" + index} {...item.data} id={item.id} value={stepData[item.id]} onChange={componentValueChanged}/>
            isDataField = true
        } else if (type == "pickabox") {
            component = <CourseComponentPickABox  {...item.data} id={item.id} columns={item.data.columns} items={item.data.items} selectedIndexes={stepData[item.id] != null ? stepData[item.id] : []} onSelect={componentMultiValueChanged} />
            dataFieldAnswersNeeded = 3
            isDataField = true
        } else if (type == "skills") {
            component = <CourseComponentSkills id={item.id} selectedIndexes={stepData[item.id] != null ? stepData[item.id] : []} onSelect={componentMultiValueChanged} />
            dataFieldAnswersNeeded = 3
            isDataField = true
        } else if (type == "continue") {
            extraProps = {
                ref: continueRef
            }
            component = <CourseComponentContinue  {...item.data}  id={item.id} buttonTitle={item.data.button} hasContinued={hasContinued} onSelect={continueComponentDidChange} />
            afterContinue = true
        }

        if (afterContinue && type != "continue") {
            if (hasContinued == false) {
                componentWaitingForContinue = true
                return null
            }
        }

        if (isDataField) {
            dataFields += dataFieldAnswersNeeded
            if (stepData[item.id] != null) { 
                if (dataFieldAnswersNeeded > 1) {
                    dataFieldsFilled += stepData[item.id].length
                } else {
                    dataFieldsFilled++ 
                }
            }
        }
            
        var cssClass = "component component-" + type

        return <section {...extraProps} className={cssClass}>
                    <div className="content">
                        {component}
                    </div>
                </section> 
    })

    var canContinue = (dataFields == dataFieldsFilled)

    
    var buttonTitle = "Continue"
    // if (dataFields > 0) {
    //     buttonTitle = "Results"
    // }

    var formFooter = <section className="form-fixed-footer">
                        <div class="content">
                            <FormAction text={footer}>
                                <FormSubmit loading={saving || preloading} disabled={!canContinue}>{buttonTitle}</FormSubmit>
                            </FormAction>
                        </div>
                    </section>  

    var description = (props.description ?? '').replace(/\\n/gi, "\n") 
    return (
        <div>
            <Form onSubmit={onSubmit}>
                <section>
                <div className="content">
                    <div className="copy-container">
                        <div className="intro"><ReactMarkdown source={description} /> </div>
                    </div>
                </div>
                </section>
            {content}        
            {componentWaitingForContinue ? null : formFooter}
            </Form>
        </div>
    )
}



export default CoursePage;


function getElementY(element) {
    return window.pageYOffset + element.getBoundingClientRect().top - 130 // offset for header
}
  
function doScrolling(element, duration) {
    if (element == null) {
        return
    }
	var startingY = window.pageYOffset
  var elementY = getElementY(element)
  //alert(ele)
  // If element is close to page's bottom then window will scroll only to some position above the element.
  var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
	var diff = targetY - startingY
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
  var start

  if (!diff) return

	// Bootstrap our animation - it will get called right before next frame shall be rendered.
	window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    var time = timestamp - start
		// Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    window.scrollTo(0, startingY + diff * percent)

		// Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}