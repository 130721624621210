import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { useFirebase } from '../Firebase';


const CourseContext = React.createContext(null);

export const useCourse = (course_access_id) => {
    let context = React.useContext(CourseContext)
    if (context) {
        return context.data
    } else {
        return null
    }
}

class CourseStateData {
    constructor(firebase) {

        // Defaults
        this.stepCache = {}
        this.status = 0
        this.course = {}
        this.current_step = 1
        this.school_id = null
        this.course_id = null
        this.userData = {}
        this.stepUserData = {}
        this.school = {}
        this.firebase = firebase
        this.session_id = sessionStorage.getItem("session_id")
        // if (this.firebase.auth.currentUser) {
        //     this.session_id = this.firebase.auth.currentUser.uid
        // }
    }

    load(access_id, finishedCallback) {
        this.firebase.db.collection('course_access').doc(access_id).get().then(accessData => {
            //var courseData = courseStateData.current
            var d = accessData.data()

            // If found access
            if (d) {
                // Assign
                this.school_id = d.school
                this.course_id = d.course
                this.status  = 1

                // Get school
                this.firebase.db.collection('schools').doc(this.school_id).get().then(result => {
                    if (result) {
                        this.school = result.data()
                    }
                })

                // Get course
                this.firebase.db.collection('courses').doc(this.course_id).get().then(result => {
                    let data = result.data()
                    if (data) {
                        this.course = data
                        this.status = 1
                    } else {
                        this.status = -1
                    }

                    // Get user data if there's an existing session
                    if (this.session_id) {
                        this.firebase.db.collection('course_user_data').doc(this.course_id).collection('results').doc(this.session_id).get().then( result => {
                        //this.firebase.db.collection('course_user_data_' + this.course_id).doc(this.session_id).get().then( result => {
                            let data = result.data()
                            if (data) {
                                this.userData = data
                                this.stepUserData = data['answers'] ?? {}
                                console.log(data)
                                finishedCallback(true)                    
                            } else {
                                finishedCallback(false)                    
                            }
                        })
                    
                    // Otherwise finish
                    } else {
                        finishedCallback(true)                    
                    }
                })

            // If not found / no access
            } else {
                this.status = -1
                console.log('not found')
                finishedCallback(true)
            }            
        })
    }

    loadCompletion(callback) {
        if (this.stepCache['completion']) {
            callback(this.stepCache['completion'])
            return
        }              
        this.firebase.db.collection('course_steps_' + this.course_id).doc('completion').get().then(accessData => {
            var d = accessData.data()
            this.stepCache['completion'] = d
            callback(d)
        })
    }

    loadWelcome(callback) {
        if (this.stepCache['welcome']) {
            callback(this.stepCache['welcome'])
            return
        }        
        this.firebase.db.collection('course_steps_' + this.course_id).doc('welcome').get().then(accessData => {
            var d = accessData.data()
            this.stepCache['welcome'] = d
            callback(d)
        })
    }    

    loadStep(step, callback) {

        var step_doc_id = this.course.steps[step - 1]


        if (step_doc_id == null) {
            return
        }

        var orig_step_doc_id = step_doc_id

        var isResults = false
        if (step_doc_id.indexOf('#results') > -1) {
            step_doc_id = step_doc_id.split('#')[0]
            isResults = true
        }
        
        if (this.course_id == null) {
            return
        }

        if (this.stepCache[orig_step_doc_id]) {
            callback(this.stepCache[orig_step_doc_id])
            return
        }

        this.firebase.db.collection('course_steps_' + this.course_id).doc(step_doc_id).get().then(accessData => {
            
            var d = accessData.data()

            var userData = this.stepUserData[step_doc_id] ?? {}
            
            var stepData = {
                title: d.title,
                footer: d.footer,
                description: d.description,
                items: [],
                userData: userData,
                isResults: isResults
            }
            
            var items = d.items ?? []

            var validTypes = ['video', 'quiz', 'input', 'skills', 'text', 'rate', 'continue', 'pickabox', 'email', 'checkbox', 'image']


            // Resolve items
            items.forEach( itemId => {
                
                var type = itemId.split('_')[0]
                var data = null
                if (validTypes.includes(type)) {
                    data = d[itemId]
                } else {
                    data = itemId
                    type = 'text'
                }

                var data = { type:  type,
                             id: itemId,
                             data:data } 
                
                stepData.items.push(data)
            })
            
            this.stepCache[orig_step_doc_id] = stepData
            callback(stepData)
        })
    }

    userDataForStepID(step_id) {
        var stepDocId = this.course.steps[step_id - 1]
        if (stepDocId == null) {
            return {}
        }
        var userData = this.stepUserData[stepDocId] ?? {}
        return userData
    }

    saveStep(step_id, data, callback) {
        var d = {}
        var stepDocId = this.course.steps[step_id - 1]
        if (stepDocId == null) {
            callback(false)
        }
        d[stepDocId] = data

        var c = {}
        c['answers'] = d

        this.stepUserData[stepDocId] = data
        data.complete = true
        data.complete_date = new Date()
        //this.firebase.db.collection('course_user_data_' + this.course_id).doc(this.session_id).set(c, { merge: true }).then(data => {
        this.firebase.db.collection('course_user_data').doc(this.course_id).collection('results').doc(this.session_id).set(c, { merge: true }).then(data => {
            callback(true)
        })

        this.sendEvent()
    }

    userMaxStep() {
        if (this.course == null || this.course.steps == null || this.stepUserData == null) {
            return 1
        }
        var max = 0
        this.course.steps.forEach( (step_id, index) => {
            if (this.stepUserData[step_id] && this.stepUserData[step_id].complete) {
                max = Math.max(max, index)
            }
        })
        return max + 1
    }

    validateEnroll(data, errorCallback) {
        var firstName = data.firstName
        var lastName = data.lastName
        var full = firstName + lastName
        if (full.replace(/[^a-z0-9\-\w]/g, '') != full) {
            errorCallback('Name can only include alphanumeric characters')
            return false
        }
        return true
    }

    enroll(formData, successCallback, errorCallback) {
        if (!this.validateEnroll(formData, errorCallback)) {
            return
        }
        this.userData = formData

        const didLogin = (user) => {
            this.session_id = user.uid
            this.saveSession()
            this.sendEvent('enroll_date', new Date() )

            this.firebase.db.collection('course_user_data').doc(this.course_id).collection('results').doc(user.uid).set({...formData, school_id: this.school_id, current_step: 0,}, { merge: true }).then( (result) => {         
            //this.firebase.db.collection('course_user_data_' + this.course_id).doc(user.uid).set({...formData, school_id: this.school_id, current_step: 0,}, { merge: true }).then( (result) => {         
                successCallback()                
                this.firebase.onAuthUserChangedEnroll = null
            }).catch(function(error) {
                alert(error)
            })
            
        }

        if (this.firebase.auth.currentUser) { 
            this.session_id = this.firebase.auth.currentUser.uid
            this.saveSession()
            didLogin(this.firebase.auth.currentUser)        
        } else {
            this.firebase.onAuthUserChangedEnroll = didLogin
             
             this.firebase.auth.setPersistence(this.firebase.AUTH_SESSION).then( () => {
                this.firebase.auth.signInAnonymously().catch(function(error) {
                    
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCallback) {
                        errorCallback(errorMessage)
                    }

                });
             })
        }


        return false
    }
    enrollOld(formData, successCallback, errorCallback) {
        console.log(formData)
        console.log(this.firebase)
        this.userData = formData
        successCallback('err')
        this.firebase.db.collection('course_user_data_' + this.course_id).add({...formData, school_id: this.school_id, current_step: 0}).then( (result) => {
            this.session_id = result.id
            this.saveSession()
            this.sendEvent('enroll_date', new Date() )
            console.log('Result ' + result.id)            
            successCallback()
            
        })
        
        
    }

    complete() {
        this.sendEvent('completion_date', new Date() )
    }

    saveSession() {
        sessionStorage.setItem("session_id", this.session_id);
    }

    sendEvent(event, value) {
        if (this.course_id == null) { return }
        var data = {
            school_id: this.school_id,
            course_id: this.course_id,
            max_step: this.userMaxStep(),  
            user_agent: navigator.userAgent,
            screen_size: window.screen ? window.screen.width + 'x' + window.screen.height : 'unknown',
            school_name: this.school ? this.school.name : null            
        }
        if (this.userData.firstName) {
            data['first_name'] = this.userData.firstName
        }
        if (this.userData.firstName) {
            data['last_name'] = this.userData.lastName
        }

        if (event) {
            data[event] = value
        }

        try {
            this.firebase.db.collection('course_user_data').doc(this.course_id).collection('sessions').doc(this.session_id).set(data, { merge: true }).then( result => {
            //this.firebase.db.collection('course_users_' + this.course_id).doc(this.session_id).set(data, { merge: true }).then( result => {
                console.log('event sent')
            })
        } catch (error) {
            alert(error)
        }
    }
}

export const CourseState = (props) => {
    const firebase = useFirebase()
    const courseStateData = React.useRef(new CourseStateData(firebase))
    //const [ courseStateData , setCourseStateData] = React.useState(new CourseData())
    const [ loaded, setLoaded ] = React.useState(false)
    
    useEffect( () => {
        console.log('Loading CourseState ' + props.accessId)
        
        courseStateData.current.load(props.accessId, () => {
            console.log(courseStateData.current.stepUserData)
            setLoaded(true)
        })
    }, [])

    let content = loaded ? props.children : null
    return <CourseContext.Provider value={{ loaded: loaded, data: courseStateData.current}}>
                {content}
            </CourseContext.Provider> 
    
}
