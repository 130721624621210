import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { useFirebase } from '../Firebase';


const AppStateContext = React.createContext(null);

export const useAppState = () => {
    let context = React.useContext(AppStateContext)
    return context
}

class AppStateData {
    //profile = AppStateProfile()
}

export const AppState = (props) => {
    const firebase = useFirebase()
    const data = React.useRef(new AppStateData())
    const [ loaded, setLoaded ] = React.useState(false)
    
    useEffect( () => {
        console.log('loading AppState')
        firebase.db.collection('ui').get().then(uiData => {
            let appData = data.current
            uiData.docs.forEach( doc => {
                if (doc.id == 'home') {
                    appData.home = doc.data()
                }                                                    
            })
            setLoaded(true)
        })
    }, [])

    let content = loaded ? props.children : props.children
    return <AppStateContext.Provider value={data.current}>
                {content}
            </AppStateContext.Provider> 
    
}

class AppStateProfile {

}
