import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import { RadioItem , RadioAnswerItem } from "../controls/Radio"
export const QuizAnswer = (props) => {
    return (<div className="quiz-item" onClick={props.onClick}>
        <RadioItem selected={props.selected}/>
        <label><span>{props.answer}</span></label>
    </div>)
}

export const CourseComponentQuiz = (props) => {
    const { id, question, answers, selectedIndex } = props

    const didSelect = (idx) => {
        if (props.onChange) {
            props.onChange(id, idx)
        }
    }
    
    const answerItems = (answers ?? []).map( (answer, index) => {
        return <QuizAnswer key={index} answer={answer} selected={index == selectedIndex} onClick={() => {
        didSelect(index)}
        }/>
    })

    return (<div className="component component-quiz">
        <h3>{question}</h3>
        <div className="quiz-items answers">
            {answerItems}
        </div>
    </div>)
}


export const CourseComponentQuizResults = (props) => {
    const { id, question, answers, correctIndex, answerIndex } = props

    var correct = (correctIndex == answerIndex)
    var answer = answers[answerIndex]

    var descriptionText = "Great job, that was the correct answer!"
    if (!correct) {
        descriptionText = <span>{"Good try! But the correct answer is "} <b>{answers[correctIndex]}</b></span>
    }


    return (<div className="component component-quiz">
        <h3>{question}</h3>
        <div className="quiz-items results">
            <div className="quiz-item" onClick={props.onClick}>
                <RadioAnswerItem correct={correct}/>
                <label>{answer}</label>
            </div>
            <div className="quiz-item" onClick={props.onClick}>
                <label>{descriptionText}</label>
            </div>            
        </div>
    </div>)
}