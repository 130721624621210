
import React, { useEffect } from 'react';


const PageHeader = (props) => {
    
    var content = []
    if (props.progress != null) {
        content.push(<div className="progressBar"><div className="inner" style={{width: (props.progress * 100) + "%"}}></div></div>)
    }
    if (props.description) {
        content.push( <p className="header">{props.description}</p>)
    }
    var supertitle = props.superTitle ? <p className="super">{props.superTitle}</p> : null
    var supertitleTop = props.progress ? null : supertitle
    var supertitleBottom = props.progress ? supertitle : null

    var logoURL = props.logo ? props.logo : '/stem-icon.png'
    var logoStyle = {
        backgroundImage: 'url(' + logoURL + ')'
    }

    return (
        <header className="page-header">
            <div className="content">
                <div className="copy-container">
                    <div className="inner">
                        
                        <div className="stem-logo" style={logoStyle}/>
                       
                        
                        {supertitleTop}
                        
                        {supertitleBottom}
                        
                        <h1>{props.title}</h1>
                        {content}
                    </div>
                </div>
            </div>
        </header>
    );
}




export default PageHeader