import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import { RadioItem } from "../controls/Radio"

export const CourseComponentRate = (props) => {
    const { title, selectedIndex, labels } = props

    var items = [0,1,2,3,4,5,6,7,8,9, 10].map( idx => {
        var itemSelected = () => {
            if (props.onChange) {
                props.onChange(props.id, idx)
            }
        }
        return <RadioItem selected={selectedIndex == idx} onClick={itemSelected}/>
    })

    var labelItems = (labels ?? []).map( label => {
        return <span>{label}</span>
    })

    return (<div className="component component-item-rate">
                <h3>{title}</h3>
                <div className="radio-items">
                    {items}
                </div>
                <div className="radio-labels">
                    {labelItems}
                </div>
            </div>)
}


export const CourseComponentRateLarge = (props) => {
    const { title, selectedIndex, labels, numberOfItems } = props

    var items = labels.map( (label, idx) => {
        var itemSelected = () => {
            if (props.onChange) {
                props.onChange(props.id, idx)
            }
        }
        return <div className="item"  onClick={itemSelected}><RadioItem label={idx + 1} selected={selectedIndex == idx}/><label><span>{label}</span></label></div>
    })


    return (<div className="component component-item-rate-large">
                <h3>{title}</h3>
                <div className="radio-items">
                    <div className="radio-items-content">
                    {items}
                    </div>
                </div>
            </div>)
}


