import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import { RadioItem } from "../controls/Radio"
import { Form, FormSelect, FormRow, FormInput, FormLabel, FormActionRow, FormSubmit, FormAction, FormError, FormHelpers, FormTextArea } from '../Form'

export const CourseComponentTextInput = (props) => {
    const { title, placeholder, value, onChange } = props

    const didChange = (event) => {
        if (props.onChange) {
            props.onChange(props.id, event.target.value)
        }
    }
    return (<div className="component component-rate">
                <h3>{title}</h3>
                <FormTextArea placeholder={placeholder} value={value} onChange={didChange}></FormTextArea>
            </div>)
}