import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import "./navigation.scss"
import { LinkSignout } from "../AppRouter"

export const Navigation = () => (
  <nav className="navigation-container white fixed">
    <div className="navigation-content">
      <ul className="navigation navigation-left">
        <li className="logo">
          <a href="https://www.futureamp.co"><div class="logo"></div></a>
        </li>
      </ul>

    </div>
  </nav>
);
//export default Navigation;

/*
      <ul className="navigation navigation-right">
        <li class="primary">
          <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
        </li>        
        <li>
          <Link to={ROUTES.SIGN_IN}>Login</Link>
        </li>
      </ul>*/


/*
        <li>
          <Link to={ROUTES.PROFILE}>Profile</Link>
        </li>
        */

        // <LinkSignout/>
export const NavigationAuthenticated = () => (
  <nav className="navigation-container white fixed">
    <div className="navigation-content">
      <ul className="navigation navigation-left">
        <li className="logo">
          <Link to={ROUTES.LANDING}><div class="logo"></div></Link>
        </li>
      </ul>
      <ul className="navigation navigation-right">
        <li>
          .
        </li>

      </ul>
    </div>
  </nav>
);
//export default Navigation;
