
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PickBoxCard = (props) => {
    var className = props.selected ? "pickbox-card selected" : "pickbox-card"
    if (props.condensed) {
        className += ' condensed'
    }
    if (props.selectable) {
        className += ' selectable'
    }

    if (props.centered) {
        className += ' centered'
    }
    
    if (props.large) {
        className += ' large'
    }

    var iconName = `${props.id}`
    if (props.iconColor) {
        iconName += '-' + props.iconColor
    }

    let content = (<div key={props.key} className={className} onClick={props.onClick}>
                        <div className="inner">
                            <h4>
                                {props.children}
                            </h4>
                            <p>
                                {props.description}
                            </p>
                        </div>
                    </div>)

    if (props.link) {
        return <Link to={'/skill/' + props.id}>{content}</Link>
    } else {
        return content
    }
}

export default PickBoxCard