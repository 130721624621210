import React from 'react';
import ReactDOM from 'react-dom';
const ReactMarkdown = require('react-markdown')

export const CourseComponentText = (props) => {
    const { text , style, id } = props
    if (text == null) {
        return "Component " + id + " empty "
    }

    var ntext = text.replace(/\\n/gi, "\n\n") 

    var content = <ReactMarkdown source={ntext} /> 
    if (style) {
        var className = "style-" + style
        content = <div className={className}>{content}</div>
    }
    return (<div className="component component-text">
                {content}
            </div>)
}