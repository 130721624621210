import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import Grid from "../controls/Grid"
import PickBoxCard from "../controls/PickBoxCard"


export const CourseComponentPickABox = (props) => {
    const { selectedIndexes, items, columns, centered } = props

    var didSelect = (id) => {
        if (props.onSelect) {
            props.onSelect(props.id, id)
        }
    }

    var skillCards = items.map( (title, index) => {
        var id = title.toLowerCase().replace(' ', '-')
        
        //var description = skill.description
        var selected = false
        selectedIndexes.forEach(selectedItem => {
            if (id == selectedItem) {
                selected = true
            }
        })
        return <PickBoxCard onClick={ () => {
            didSelect(id)
        }}  centered={centered} selectable condensed id={id} selected={selected} iconName={id} description={""}>{title}</PickBoxCard>
    })

    return (
        <div class="component component-skills">
        {props.title ? <h3>{props.title}</h3> : null}
        <Grid columns={columns ? columns : 2}>            
            {skillCards}
        </Grid>
        </div>
    )    


}


export const CourseComponentPickABoxResults = (props) => {
    const { selectedIndexes, items, selectedTitle, unselectedTitle, centered } = props

    var selectedSkills = []
    var unselectedSkills = []
    
    items.forEach( title => {
        var id = title.toLowerCase().replace(' ', '-')

        var selected = false
        selectedIndexes.forEach(selectedItem => {
            if (id == selectedItem) {
                selected = true
            }
        })

        var card = <PickBoxCard centered={centered} condensed id={id}>{title}</PickBoxCard>
        if (selected) {
            selectedSkills.push(card)
        } else {
            unselectedSkills.push(card)
        }
    })

    //{selectedTitle ? <p className="intro" style={{marginBottom: "30px"}}>{selectedTitle}</p> : null}
    //{unselectedTitle ? <p className="intro intro-border" style={{marginBottom: "35px"}}>{unselectedTitle}</p> : null}
    return (
        <div class="component component-skills">
        
        <div class="skill-results-selected">
            {selectedTitle ? <h3 className="no-separator">{selectedTitle}</h3> : null}
            
            <Grid columns={3}>           
            {selectedSkills}
            </Grid>
        </div>
        
        <div class="skill-results-selected" style={{paddingTop: "100px"}}>
        {unselectedTitle ? <h3 className="no-separator">{unselectedTitle}</h3> : null}
            
            <Grid columns={3}>           
            {unselectedSkills}
            </Grid>
        </div>
        </div>
    )   

}