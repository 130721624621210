import React from 'react';
import useReactRouter from 'use-react-router';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { useFirebase } from '../Firebase';

export const useAppRouter = () => {
    const { history, location, match } = useReactRouter();

    return {
        landing: () => {
            history.push(ROUTES.LANDING)   
        },
        home: () => {
            history.push(ROUTES.LANDING)
        },
        courseEnroll: (course_name, course_access_id) => {
            history.push('/courses/' + course_name + '/' + course_access_id + '/enroll')
        },
        courseStep: (course_name, course_access_id, course_step) => {
            history.push('/courses/' + course_name + '/' + course_access_id + '/step/' + course_step)
        },
        courseComplete:(course_name, course_access_id) => {
            history.push('/courses/'  + course_name + '/' + course_access_id + '/complete')
        }
    }
}


export const LinkSignup = () => {
    return <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
}


export const LinkLogin = () => {
    return <Link to={ROUTES.SIGN_IN}>Login</Link>
}

export const LinkSignout = () => {
    const firebase = useFirebase()
    const router = useAppRouter()

    const signOut = (event) => {
        firebase.signOut()
        router.landing()
        return false
    }

    return (
        <a href="#" onClick={signOut}>Sign Out</a>
    )
}

