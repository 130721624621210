import React from 'react';

/** Base */
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { useFirebase } from '../Firebase';
import { AppState } from '../AppState'
import { CourseState } from '../Course'

/** Pages */
import { Navigation, NavigationAuthenticated } from '../Navigation';
import LandingPage from '../LandingPage'
import { CourseLandingPage, CourseEnrollPage, CoursePage }  from '../CoursePage'

/** CSS */
import './style.scss';

import ScrollToTop from "../ScrollToTop";




const App = () => {
  const [ authenticatedUser, setAuthenticatedUser ] = React.useState(null)
  const firebase = useFirebase()
  
  React.useEffect( () => {
    setAuthenticatedUser(firebase.user)
    firebase.onAuthUserChanged = (user) => {
      setAuthenticatedUser(user)
    }

    return () => {
      firebase.onAuthUserChanged = null
    }
  }, [])

  return (
    <Router>
        <ScrollToTop/>
       { authenticatedUser ? <NavigationAuthenticated/> : <Navigation />  }
        <main className="page">

          <AppState>
            <Route path={ROUTES.COURSE_LEGACY} component={CoursePage}/>
            <Route path={ROUTES.COURSE} component={CoursePage} />
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
          </AppState>
        </main>
    </Router>
  )
};
export default App;
