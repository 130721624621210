import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'

export const RadioItem = (props) => {
    const { selected, onClick, label, innerLabel } = props
    var className = "radio selectable"
    if (selected) {
        className += " radio-selected"
    }
    return <div className={className} onClick={onClick}>
        <div className="inner">{label ? <span>{label}</span> : null }</div>
    </div>
}


export const RadioAnswerItem = (props) => {
    const { correct, onClick } = props
    var className = "radio"
    if (correct) {
        className += " radio-correct"
    } else {
        className += " radio-incorrect"
    }

    return <div className={className} onClick={onClick}>
        <div className="inner"></div>
    </div>
}
